
import { defineComponent } from 'vue';
import { getCurrentInstance, ref, computed, watch, onMounted, onUnmounted } from "vue";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Tooltip from 'primevue/tooltip';
import { Helper } from "@/util/typeHelper";
import { TxnExchangeOffer } from "@/models/transactions/exchangeOffer"
import { AppState } from '@/state/appState';

export default defineComponent({
  components: {
    DataTable,
    Column,
  },
  name: 'ExchangeTxnDataTable',
  props: {
    transactions: Array,
    pages: Number,
    selectedGroupType: String,
  },
  directives: {
    'tooltip': Tooltip
  },
  setup(p, context){
    const wideScreen = ref(false);
    const screenResizeHandler = () => {
      if(window.innerWidth < 1024){
        wideScreen.value = false;
      }else{
        wideScreen.value = true;
      }
    };
    screenResizeHandler();

    onUnmounted(() => {
      window.removeEventListener("resize", screenResizeHandler);
    });

    onMounted(() => {
      window.addEventListener("resize", screenResizeHandler);
    });

    const internalInstance = getCurrentInstance();
    const emitter = internalInstance.appContext.config.globalProperties.emitter;
    const transactionGroupType = Helper.getTransactionGroupType();

    const nativeTokenName = computed(() => AppState.nativeToken.label);

    const convertLocalTime = (dateTimeInJSON)=>{
      return Helper.convertDisplayDateTimeFormat24(dateTimeInJSON);
    };

    const displayExchangeSDA = (exchangeOffer: TxnExchangeOffer)=>{

      let asset_div = (exchangeOffer.amount > 0)?(Helper.convertToCurrency(exchangeOffer.amount, 0) + " "):'';
      asset_div += (exchangeOffer.assetNamespace ?  exchangeOffer.assetNamespace : exchangeOffer.assetId);
      asset_div += (exchangeOffer.cost > 0)?(" - " + Helper.convertToCurrency(exchangeOffer.cost, 0) + " " + nativeTokenName.value):'';

      return asset_div;
    }

    return {
      displayExchangeSDA,
      nativeTokenName,
      convertLocalTime,
      transactionGroupType,
      wideScreen,
      Helper,
    }
  }
})
